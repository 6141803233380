<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <div class="px-8 py-3 my-12 bg-white rounded container">
          <h4 class="font-subtitle-2 text-subtitle-1 my-6 inline">Detail category</h4>
          <ButtonSolidBox class="px-4 py-1 ml-4 rounded-full" @click="toggleEdit()">
              {{ btnEditLabel }}
          </ButtonSolidBox>
          <div class="flex flex-row items-stretch gap-x-8 pt-6">
            <Loading :show="!CATEGORY_READY" />
          </div>
          <div class="flex flex-row items-stretch gap-x-8 pt-6">
            <div class="inline-block align-middle w-1/2">
              <InputTextBox
                class="block"
                placeholder="Category name"
                @input="val => processValue(val,'article_category_name')"
                :warning="warning.article_category_name"
                :disabled="disabled"
                :value="GET_CATEGORY.article_category_name"
                warningClass="text-red-500"
              ></InputTextBox>
            </div>
          </div>
          <div class="my-12 w-full">
            <!-- <Loading :show="!CATEGORY_READY" /> -->
            <Alert relative normal v-show="storeError">Failed to update category.</Alert>
          </div>
          <div class="my-12 w-full">
            <ButtonSolidBox class="px-8" @click="submitForm()" v-show="!disabled">
              Save
            </ButtonSolidBox>
          </div>
        </div>
        <Modal name="Category" :show.sync="showModal"  strict-close>
          <div class="flex flex-col align-items-center w-full p-12">
            <div class="mx-auto my-8 text-center">
              <svg
                class="block mx-auto"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  fill="white"
                  fill-opacity="0.12"
                />
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  fill="#48D5D1"
                  fill-opacity="0.12"
                />
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  stroke="#48D5D1"
                  stroke-width="1.5"
                />
                <path
                  d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
                  fill="#48D5D1"
                />
              </svg>
              <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
                Category has been updated!
              </div>
              <div
                class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
              >
                Category has been updated successfully.
              </div>
              <ButtonSolidBox v-show="CATEGORY_READY" class="px-8" @click="closeModal()">
                Ok
              </ButtonSolidBox>
            </div>
          </div>
        </Modal>
      </div>
    </div>
    <!-- <TheSideCalendarBar /> -->
  </div>
</template>

<style scoped>
 </style>

<script>
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
// import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import Modal from '@/components/Modal.vue'
import { createNamespacedHelpers } from 'vuex'

const category = createNamespacedHelpers('category')

export default {
  components: {
    Loading,
    Alert,
    InputTextBox,
    ButtonSolidBox,
    Modal,
    TheContentTopNavigationBar: TheContentTopNavigationBar,
    // TheSideCalendarBar: TheSideCalendarBar,
    TheSideNavigationBar: TheSideNavigationBar
  },
  data () {
    return {
      btnEditLabel: 'Edit',
      storeError: false,
      showModal: false,
      disabled: true,
      warning: {
        fullname: '',
        email: '',
        phone: '',
        gender: '',
        alamat: '',
        npwp: '',
        role: ''
      }
    }
  },
  computed: {
    ...category.mapGetters(['CATEGORY_READY', 'GET_CATEGORY'])
  },
  created () {
    // this.createInit()
    this.getCategoryById({ id: this.$route.params.id })
  },
  methods: {
    ...category.mapActions(['getCategoryById', 'updateCategory']),
    toggleEdit () {
      this.disabled = !this.disabled
      this.btnEditLabel = this.disabled ? 'Edit' : 'Cancel'
      if (this.disabled) {
        this.getCategoryById({ id: this.$route.params.id })
      }
    },
    getSubmitButtonLabel () {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, 'username') &&
        `${this.$route.params.username}`.length > 0
      ) {
        return 'Update'
      }
      return 'Add User'
    },
    processValue (value, target) {
      if (value === '') {
        this.warning[target] = 'This field is required'
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }
        this.GET_CATEGORY[target] = value
      }
    },
    validate () {
      var validation = true
      Object.keys(this.GET_CATEGORY).forEach(val => {
        if (this.GET_CATEGORY[val] === '') {
          validation = false
          this.warning[val] = 'This field is required'
        }
      })
      return validation
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false
      this.updateCategory({ form: this.GET_CATEGORY }).then((data) => {
        if (data.data !== null) {
          this.toggleEdit()
          this.showModal = true
        }
      }).catch(err => {
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    closeModal () {
      this.showModal = false
    }
  }
}
</script>
