<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <div class="px-8 py-3 my-12 bg-white rounded container">
          <h4 class="font-subtitle-2 text-subtitle-1 my-6 inline">Detail Internal User</h4>
          <ButtonSolidBox class="px-4 py-1 ml-4 rounded-full" @click="toggleEdit()">
              {{ btnEditLabel }}
          </ButtonSolidBox>
          <div class="flex flex-row items-stretch gap-x-8 pt-6">
            <Loading :show="!INTERNAL_USER_READY" />
          </div>
          <div class="flex flex-row items-stretch gap-x-8 pt-6">
            <div class="inline-block align-top w-1/2 py-1 max-h-0">
              <InputImage type="avatar" />
            </div>

            <div class="inline-block align-middle w-1/2">
              <InputTextBox
                class="block"
                placeholder="Fullname"
                @input="val => processValue(val,'fullname')"
                :warning="warning.fullname"
                :disabled="disabled"
                :value="GET_INTERNAL_USER.fullname"
                warningClass="text-red-500"
              ></InputTextBox>
              <InputTextBox
                type="email"
                class="block mt-3"
                placeholder="Email"
                :disabled="disabled"
                :value="GET_INTERNAL_USER.email"
                :warning="warning.email"
                warningClass="text-red-500"
                @input="val => processValue(val,'email')"
              ></InputTextBox>
            </div>
          </div>
          <div class="flex flex-row gap-x-8 py-3">
            <InputTextBox
              class="inline-block w-1/2 h-12"
              placeholder="NPWP"
              @input="val => processValue(val,'npwp')"
              :disabled="disabled"
              :warning="warning.npwp"
              :value="GET_INTERNAL_USER.npwp"
              warningClass="text-red-500"
            ></InputTextBox>
            <InputDropdown
              class="inline-block w-1/2 h-12"
              :options="['male', 'female']"
              placeholder="Gender"
              :disabled="disabled"
              :value="GET_INTERNAL_USER.gender"
              @input="val => processValue(val,'gender')"
              :warning="warning.gender"
              warningClass="text-red-500"
            ></InputDropdown>
          </div>
          <div class="flex flex-row gap-x-8 py-6">
            <InputTextBox
              class="inline-block w-1/2 h-12"
              placeholder="Phone number"
              :disabled="disabled"
              :value="GET_INTERNAL_USER.phone"
              @input="val => processValue(val,'phone')"
              :warning="warning.phone"
              warningClass="text-red-500"
            ></InputTextBox>
            <InputDropdown
              class="inline-block w-1/2 h-12"
              :options="['writter', 'auditor', 'admin', 'owner']"
              :value="GET_INTERNAL_USER.role"
              placeholder="Role"
              :disabled="disabled"
              @input="val => processValue(val,'role')"
              :warning="warning.role"
              warningClass="text-red-500"
            ></InputDropdown>
            <!-- <InputTextBox
              type="password"
              class="inline-block w-1/2 h-12"
              placeholder="Password"
              :disabled="disabled"
              @input="val => processValue(val,'password')"
              :warning="warning.password"
              warningClass="text-red-500"
            ></InputTextBox> -->
          </div>
          <div class="flex flex-row gap-x-8 pt-6">
            <InputTextArea
              class="inline-block w-1/2"
              placeholder="Address"
              :disabled="disabled"
              @input="val => processValue(val,'alamat')"
              :warning="warning.alamat"
              :value="GET_INTERNAL_USER.alamat"
              warningClass="text-red-500"
            ></InputTextArea>
            <div class="inline-block w-1/2 h-12"></div>
          </div>
          <div class="my-12 w-full">
            <Loading :show="!INTERNAL_USER_READY" />
            <Alert relative normal v-show="storeError">Failed to update internal user.</Alert>
          </div>
          <div class="my-12 w-full">
            <ButtonSolidBox class="px-8" @click="submitForm()" v-show="!disabled">
              Save
            </ButtonSolidBox>
          </div>
        </div>
        <Modal name="user" :show.sync="showModal"  strict-close>
          <div class="flex flex-col align-items-center w-full p-12">
            <div class="mx-auto my-8 text-center">
              <svg
                class="block mx-auto"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  fill="white"
                  fill-opacity="0.12"
                />
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  fill="#48D5D1"
                  fill-opacity="0.12"
                />
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  stroke="#48D5D1"
                  stroke-width="1.5"
                />
                <path
                  d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
                  fill="#48D5D1"
                />
              </svg>
              <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
                User has been updated!
              </div>
              <div
                class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
              >
                User has been updated successfully.
              </div>
              <ButtonSolidBox v-show="INTERNAL_USER_READY" class="px-8" @click="closeModal()">
                Ok
              </ButtonSolidBox>
            </div>
          </div>
        </Modal>
      </div>
    </div>
    <!-- <TheSideCalendarBar /> -->
  </div>
</template>

<style scoped>
 </style>

<script>
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
// import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'
import InputImage from '@/components/Inputs/InputImage.vue'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputTextArea from '@/components/Inputs/InputTextArea.vue'
import InputDropdown from '@/components/Inputs/InputDropdown.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import Modal from '@/components/Modal.vue'
import { createNamespacedHelpers } from 'vuex'

const internalUser = createNamespacedHelpers('internalUser')

export default {
  components: {
    Loading,
    Alert,
    InputTextBox,
    InputDropdown,
    InputTextArea,
    ButtonSolidBox,
    InputImage,
    Modal,
    TheContentTopNavigationBar: TheContentTopNavigationBar,
    // TheSideCalendarBar: TheSideCalendarBar,
    TheSideNavigationBar: TheSideNavigationBar
  },
  data () {
    return {
      btnEditLabel: 'Edit',
      storeError: false,
      showModal: false,
      disabled: true,
      warning: {
        fullname: '',
        email: '',
        phone: '',
        gender: '',
        alamat: '',
        npwp: '',
        role: ''
      }
    }
  },
  computed: {
    ...internalUser.mapGetters(['INTERNAL_USER_READY', 'GET_INTERNAL_USER'])
  },
  created () {
    // this.createInit()
    this.getUserById({ id: this.$route.params.id })
  },
  methods: {
    ...internalUser.mapActions(['getUserById', 'updateInternalUser']),
    toggleEdit () {
      this.disabled = !this.disabled
      this.btnEditLabel = this.disabled ? 'Edit' : 'Cancel'
      if (this.disabled) {
        this.getUserById({ id: this.$route.params.id })
      }
    },
    getSubmitButtonLabel () {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, 'username') &&
        `${this.$route.params.username}`.length > 0
      ) {
        return 'Update'
      }
      return 'Add User'
    },
    processValue (value, target) {
      if (value === '') {
        this.warning[target] = 'This field is required'
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }
        this.GET_INTERNAL_USER[target] = value
      }
    },
    validate () {
      var validation = true
      Object.keys(this.GET_INTERNAL_USER).forEach(val => {
        if (this.GET_INTERNAL_USER[val] === '') {
          validation = false
          this.warning[val] = 'This field is required'
        }
      })
      return validation
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false
      this.updateInternalUser({ form: this.GET_INTERNAL_USER }).then((data) => {
        if (data.data !== null) {
          this.toggleEdit()
          this.showModal = true
        }
      }).catch(err => {
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    closeModal () {
      this.showModal = false
    }
  }
}
</script>
